<template>
  <div class="loading-animated">
    <slot :primary="primaryColor" :secondary="secondaryColor">
      <vcl-code :primary="primaryColor" :secondary="secondaryColor"></vcl-code>
    </slot>
  </div>
</template>

<script>
import {VclCode} from 'vue-content-loading';

export default {
  data() {
    return {
      primaryColor: '#DBD8D4',
      secondaryColor: '#CCC',
    };
  },
  components: {VclCode},
};
</script>

<style lang="scss" scoped>
.loading-animated {
  display: flex;
}
</style>
