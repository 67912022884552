<template>
  <router-link :to="item.to">{{ item.text }}</router-link>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style scoped></style>
