<template>
  <v-dropdown
    class="share-popover"
    popper-class="under-main-menu"
    :autoHide="true"
    placement="bottom"
    :overflow-padding="10"
    :flip="false"
  >
    <mcr-button v-if="isButton" class="share-button">Share</mcr-button>
    <a class="share-button" v-else>
      <share-icon :size="22"></share-icon>
      <span>Share</span>
    </a>
    <div slot="popper" class="popover-content">
      <div class="social-share-links">
        <div class="input-label">Share on Social Media</div>
        <div class="social-share-buttons">
          <social-share-button
            v-for="network in socialNetworks"
            :key="network"
            :url="shareUrl"
            :network="network"
            :title="title"
            rel="nofollow"
            @open="trackClickSocialShareButton"
          ></social-share-button>
        </div>
      </div>
      <div class="copy-share-link">
        <div class="input-label">Share Link</div>

        <div class="clipboard-input">
          <input
            class="copy-link-input input"
            ref="link"
            readonly
            :value="shareUrl"
            v-clipboard:copy="shareUrl"
            v-clipboard:success="copyLinkSuccess"
          />
          <transition name="fade">
            <div v-if="displayCopiedSuccess" class="copy-success">
              <div class="message">Copied!</div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </v-dropdown>
</template>

<script>
import SocialShareButton from '@common/elements/buttons/SocialShareButton';
import McrButton from '@common/elements/buttons/mcrButton';
import {networks} from '@common/elements/buttons/utils.social-sharing';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import ShareIcon from 'vue-material-design-icons/Share';

export default {
  props: {
    /* url or route is required */
    url: {required: false, type: String},
    route: {required: false, type: Object},

    trackParams: {type: Object, default: () => ({})},

    title: String,
    isButton: {type: Boolean, default: false},
  },
  data() {
    return {
      displayCopiedSuccess: false,
    };
  },
  computed: {
    socialNetworks() {
      return Object.keys(networks);
    },
    shareUrl() {
      if (this.url) {
        return this.url;
      }
      const href = this.$router.resolve(this.route).href;
      return `${window.location.origin}${href}`;
    },
  },
  methods: {
    copyLinkSuccess() {
      this.$refs.link.select();
      this.displayCopiedSuccess = true;
      AnalyticsMainHandler.trackClickShareLinkEvent(getRoutePageName(this.$route), this.trackParams);
      setTimeout(() => {
        this.displayCopiedSuccess = false;
      }, 1000);
    },
    trackClickSocialShareButton({key, url}) {
      AnalyticsMainHandler.trackClickSocialShareLinkEvent(getRoutePageName(this.$route), key, this.trackParams);
    },
  },
  components: {ShareIcon, SocialShareButton, McrButton},
  name: 'ShareUrlButton',
};
</script>

<style lang="scss" scoped>
.social-share-buttons {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;

  @media only screen and (max-width: $breakpoint-mobile-se) {
    flex-direction: column;
  }
}

.share-button {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.popover-content {
  max-width: 650px;
  padding: 20px;
  .copy-share-link {
    margin-top: 20px;
  }
}

.clipboard-input {
  position: relative;
  width: 100%;
  .copy-link-input {
    width: 100%;
  }

  .copy-success {
    position: absolute;
    right: 7px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .message {
      padding: 4px;
      border-radius: 4px;
      background-color: $success-color;
      color: $off-white;
    }
  }
}

.input-label {
  color: $neutral-600;
}
</style>
