<template>
  <div class="wiki-article-page">
    <wiki-header :steps="headerSteps"></wiki-header>

    <div class="limited_content">
      <wiki-admin :article="wikiArticleState"></wiki-admin>
      <div v-if="notFound">Article not found. Please try searching for a keyword.</div>
      <wiki-loading v-else-if="!wikiArticleState || wikiArticleLoadingState"></wiki-loading>
      <template v-else>
        <article-content :article="wikiArticleState">
          <template v-slot:title-right>
            <share-url-button
              :route="shareRoute"
              :title="shareTitle"
              :track-params="shareTrackParams"
            ></share-url-button>
          </template>
        </article-content>
        <div class="clear-div"></div>
        <cta-block-compare-plans></cta-block-compare-plans>
      </template>
    </div>

    <end-actions>
      <template v-if="wikiArticleState">
        Was this article helpful? <a href="mailto:feedback@mychinaroots.com" target="_blank">Suggest an improvement</a>
      </template>
    </end-actions>
    <go-up-button></go-up-button>
  </div>
</template>

<script>
import GoUpButton from '@common/elements/buttons/GoUpButton';
import ShareUrlButton from '@common/elements/buttons/ShareUrlButton';
import EndActions from '@common/elements/buttons/endActions';
import WikiHeader from '@common/pages/wiki/WikiHeader';
import WikiLoading from '@common/pages/wiki/WikiLoading';
import WikiAdmin from '@common/pages/wiki/admin/WikiAdmin';
import ArticleContent from '@common/pages/wiki/article/ArticleContent';
import wikiArticle from '@common/pages/wiki/js/wikiArticle';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

export default {
  metaInfo() {
    return wikiArticle.getMetaInfo(this);
  },
  created() {
    wikiArticle.onArticleCreated(this);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevStep = wikiArticle.getPrevStep(from, vm);
    });
  },
  data() {
    return {
      fetchRan: false,
      prevStep: null,
    };
  },
  computed: {
    ...mapGetters(['wikiArticleState', 'wikiCategoryState', 'wikiArticleLoadingState']),
    headerSteps() {
      return wikiArticle.getHeaderSteps(this);
    },
    notFound() {
      return this.fetchRan && !this.wikiArticleState;
    },
    shareRoute() {
      return {name: this.$route.name, params: this.$route.params};
    },
    shareTitle() {
      return `Check out this page I found on "${this.wikiArticleState.title}":`;
    },
    shareTrackParams() {
      return {'wiki-slug': this.wikiArticleState.slug};
    },
  },
  components: {
    CtaBlockComparePlans,
    WikiLoading,
    WikiAdmin,
    ArticleContent,
    WikiHeader,
    EndActions,
    GoUpButton,
    ShareUrlButton,
  },
};
</script>

<style scoped lang="scss">
@import '~@common/pages/wiki/styles/details.scss';
</style>
