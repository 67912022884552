<template>
  <div class="wiki-search-result-item">
    <router-link :to="toRoute" class="visitable">{{ article.title }}</router-link>
    <div class="summary">
      {{ summary }}
    </div>
    <span class="supplemental preview-link">{{ href }}</span>
  </div>
</template>

<script>
export default {
  props: {
    article: Object,
  },
  computed: {
    toRoute() {
      const slug = this.article.slug;
      return this.article.is_category
        ? {name: 'wiki-category', params: {slug}}
        : {name: 'wiki-article', params: {slug}};
    },
    href() {
      const path = decodeURI(this.$router.resolve(this.toRoute).href);
      return `${window.location.origin}${path}`;
    },
    summary() {
      return this.article.content;
    },
  },
};
</script>

<style lang="scss" scoped>
.wiki-search-result-item {
  padding: 20px 0;

  .summary {
    margin: 10px 0;
  }

  .preview-link {
    word-break: break-all;
  }
}
</style>
