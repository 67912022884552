<template>
  <div>{{ item }}</div>
</template>

<script>
export default {
  props: {
    item: String,
  },
  name: 'RowItemDiv',
};
</script>

<style scoped></style>
