<template>
  <a :href="item.href" target="_blank">{{ item.text }}</a>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  name: 'RowItemLink',
};
</script>

<style scoped></style>
