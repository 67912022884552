<template>
  <engaging-stand-out-block :title="titleDisplay" v-if="!userIsSubscribedState && titleDisplay">
    <template v-slot:button>
      <subscription-plans-link class="fat white" :on-click="trackClick">
        <span>{{ action }}</span>
        <arrow-right-icon :size="20"></arrow-right-icon>
      </subscription-plans-link>
    </template>
  </engaging-stand-out-block>
</template>

<script>
import EngagingStandOutBlock from '@common/elements/layouts/EngagingStandOutBlock';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight';
import {mapGetters} from 'vuex';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink';

export default {
  props: {
    title: {type: String, default: ''},
    trackTitle: {type: String, default: ''},
    action: {type: String, default: 'Get Started'},
  },
  computed: {
    ...mapGetters(['userIsSubscribedState', 'featureSwitchesState', 'featureSwitchesLoadedState']),
    titleDisplay() {
      if (this.title) {
        return this.title;
      }
      if (!this.featureSwitchesLoadedState) {
        return '';
      }
      return this.featureSwitchesState.GENERIC_CTA_AB1
        ? 'Research and preserve your family history.'
        : 'Uncover the stories of your Chinese ancestors.';
    },
  },
  methods: {
    trackClick() {
      const trackTitle = this.trackTitle || this.titleDisplay;
      AnalyticsMainHandler.trackClicGenericCtaBlock(
        this.action,
        trackTitle,
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route)
      );
    },
  },
  name: 'ctaBlockComparePlans',
  components: {SubscriptionPlansLink, EngagingStandOutBlock, ArrowRightIcon},
};
</script>

<style lang="scss" scoped>
.engaging-stand-out-block {
  margin-top: 50px;
  background-image: linear-gradient($primary-200, $primary-600);
  color: $background-light;

  &::v-deep .title {
    color: $background-light;
  }

  &::v-deep span + .material-design-icon {
    margin-left: 8px;
  }
}
</style>
