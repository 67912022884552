export default {
  getMetaInfo(vm) {
    return {
      title: vm.wikiCategoryState ? vm.wikiCategoryState.details_article.title : 'Chinese Genealogy Wiki',
    };
  },
  getPrevStep(fromRoute, toRoute, vm) {
    const {name, query, params} = fromRoute;
    if (name === 'wiki-search') {
      return {route: {name, query, params}, label: 'Search results'};
    }
    if (name === 'wiki-category' && vm.childCategoriesSlugs.includes(toRoute.params.slug)) {
      return {route: {name, query, params}, label: vm.wikiCategoryState.details_article.title};
    }
  },
  init(vm) {
    vm.fetchRan = false;
    vm.$store.dispatch('fetchWikiCategoryAction', vm.$route.params.slug).finally(() => {
      vm.fetchRan = true;
    });
  },
  getHeaderSteps(vm) {
    const label =
      vm.wikiCategoryState && !vm.wikiCategoryLoadingState ? vm.wikiCategoryState.details_article.title : '';
    const curStep = {label};
    if (vm.prevStep) {
      return [vm.prevStep, curStep];
    }
    return [curStep];
  },
};
