export default {
  getMetaInfo(vm) {
    return {
      title: vm.wikiArticleState ? vm.wikiArticleState.title : 'Chinese Genealogy Wiki',
    };
  },
  onArticleCreated(vm) {
    if (vm.wikiArticleState && vm.wikiArticleState.slug === vm.$route.params.slug) {
      return;
    }
    vm.$store.dispatch('fetchWikiArticleAction', vm.$route.params.slug).finally(() => {
      vm.fetchRan = true;
    });
  },
  getPrevStep(fromRoute, vm) {
    const {name, query, params} = fromRoute;
    if (name === 'wiki-article') {
      return {route: {name, query, params}, label: vm.wikiArticleState.title};
    }
    if (name === 'wiki-category') {
      return {route: {name, query, params}, label: vm.wikiCategoryState.details_article.title};
    }
    if (name === 'wiki-search') {
      return {route: {name, query, params}, label: 'Search results'};
    }
  },
  getHeaderSteps(vm) {
    const label = vm.wikiArticleState && !vm.wikiArticleLoadingState ? vm.wikiArticleState.title : '';
    const curStep = {label};
    if (vm.prevStep) {
      return [vm.prevStep, curStep];
    }
    return [curStep];
  },
};
