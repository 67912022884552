<template>
  <div class="wiki-loading">
    <loading-animated class="content">
      <vcl-list slot-scope="props" :primary="props.primary" :secondary="props.secondary"></vcl-list>
    </loading-animated>
    <loading-animated class="list">
      <vcl-bullet-list slot-scope="props" :primary="props.primary" :secondary="props.secondary"></vcl-bullet-list>
    </loading-animated>
  </div>
</template>

<script>
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import {VclBulletList, VclList} from 'vue-content-loading';

export default {
  components: {LoadingAnimated, VclList, VclBulletList},
};
</script>

<style lang="scss" scoped>
.wiki-loading {
  .content {
    max-width: 1000px;
  }
  .list {
    max-width: 500px;
  }
}
</style>
