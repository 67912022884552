<template>
  <div class="paragraph-section">
    <h5 class="title" v-if="$slots.title"><slot name="title"></slot></h5>

    <div class="paragraph-section-content">
      <div class="meta-side" v-if="$slots.meta">
        <slot name="meta"></slot>
      </div>
      <div class="text-side" v-if="$slots.text">
        <slot name="text"></slot>
      </div>
    </div>

    <div class="after-text" v-if="$slots['after-text']">
      <slot name="after-text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphSection',
};
</script>

<style lang="scss" scoped>
.paragraph-section-content {
  .text-side {
    word-break: break-word;

    &::v-deep {
      a {
        font-weight: 600;
      }
      .table-of-contents a {
        font-weight: 400;
      }
    }
  }

  .meta-side {
    width: 50%;
    max-width: 450px;
    margin: 5px 0 5px 32px;
    float: right;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .meta-side {
      width: 100%;
      max-width: 100%;
      margin: 20px 0;
      float: none;
    }
  }
}
</style>
