<template>
  <mcr-button class="go-up-button" v-show="showGoToTop" @click="goToTop">
    <arrow-icon></arrow-icon>
  </mcr-button>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {scrollPastElement} from '@common/utils/utils';
import ArrowIcon from 'vue-material-design-icons/ArrowUp';

export default {
  props: {
    selector: {type: String, default: '.search input'},
  },
  data() {
    return {
      showGoToTop: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    element() {
      return document.querySelector(this.selector);
    },
  },
  methods: {
    handleScroll() {
      this.showGoToTop = scrollPastElement(this.element);
    },
    goToTop() {
      this.$scrollTo({x: 0, y: 0});
    },
  },
  components: {McrButton, ArrowIcon},
  name: 'GoUpButton',
};
</script>

<style lang="scss" scoped>
.go-up-button {
  position: fixed;
  z-index: $modal-z-index;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;

  width: 50px;
  height: 50px;
  min-width: 0;
}
</style>
