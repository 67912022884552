<template>
  <div class="article-meta-row">
    <span class="title"
      ><strong>{{ title }}</strong></span
    >
    <div class="items">
      <template v-for="item in items">
        <slot name="item" :item="item">{{ item }}</slot>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
.article-meta-row {
  display: flex;
  justify-content: space-between;

  .items {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
}
</style>
