<template>
  <div class="wiki-loading">
    <loading-animated>
      <vcl-list slot-scope="props" :primary="props.primary" :secondary="props.secondary"></vcl-list>
    </loading-animated>
    <loading-animated>
      <vcl-list slot-scope="props" :primary="props.primary" :secondary="props.secondary"></vcl-list>
    </loading-animated>
  </div>
</template>

<script>
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import {VclList} from 'vue-content-loading';

export default {
  props: {
    hasList: Boolean,
  },
  components: {LoadingAnimated, VclList},
};
</script>

<style lang="scss" scoped>
.wiki-loading {
  max-width: 1000px;
}
</style>
