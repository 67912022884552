<template>
  <div class="images-preview">
    <div v-for="image in visibleImagesData" class="image-container" @click="onImageClick(image)">
      <img v-if="image.previewSrc" :src="image.previewSrc" :data-src="image.src" class="lazyload" />
      <img v-else :src="image.src" />
    </div>
    <div class="image-container more-images-count" v-if="moreImagesCount > 0" @click="onMoreClick">
      <span>+ {{ moreImagesCount }} Photos</span>
    </div>
  </div>
</template>

<script>
import {getGalleryImageData, getImageLazyLoadPlaceholderSrc, getImageSrc} from '@common/utils/utils';
import {mapGetters} from 'vuex';

export default {
  props: {
    imagesSrc: Array,
    lazyLoad: {type: Boolean, default: false},
    setupGalleryData: {type: Function},
  },
  data() {
    return {
      displayPhotosCountDesktop: 7,
      displayPhotosCountMobile: 5,
      displayPhotosCountPhablet: 3,
    };
  },
  computed: {
    ...mapGetters(['windowWidthState']),
    imagesData() {
      return this.imagesSrc.map((src, index) => {
        if (this.lazyLoad) {
          return {previewSrc: getImageLazyLoadPlaceholderSrc(src), src: getImageSrc(src), id: index};
        }
        return {src, id: index};
      });
    },
    totalImagesCount() {
      return this.imagesData.length;
    },
    displayImagesCount() {
      if (this.windowWidthState <= this.$breakpoints.phablet) {
        return this.displayPhotosCountPhablet;
      }
      if (this.windowWidthState <= this.$breakpoints.mobile) {
        return this.displayPhotosCountMobile;
      }
      return this.displayPhotosCountDesktop;
    },
    visibleImagesData() {
      // avoid showing +1 Photo
      return this.totalImagesCount - this.displayImagesCount > 1
        ? this.imagesData.slice(0, this.displayImagesCount)
        : this.imagesData;
    },
    visibleImagesCount() {
      return this.visibleImagesData.length;
    },
    moreImagesCount() {
      return this.totalImagesCount - this.visibleImagesCount;
    },
  },
  methods: {
    onImageClick(image) {
      this.setupGallery();
      this.$store.dispatch('displayGalleryAction', image.id);
    },
    onMoreClick() {
      this.setupGallery();
      this.$store.dispatch('displayGalleryAction', this.displayImagesCount);
    },
    setupGallery() {
      let data = this.setupGalleryData
        ? this.setupGalleryData(this.imagesData)
        : this.imagesData.map(item => {
            return getGalleryImageData(item.id, item.src);
          });
      this.$store.commit('setGalleryItemsState', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.images-preview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;

  .image-container {
    cursor: pointer;
    border: var(--report-gallery-border);
    box-shadow: var(--report-gallery-box-shadow);
    transition: all 0.5s ease;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
    }

    &:hover {
      border-color: var(--report-image-hover-border-color);
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  .more-images-count {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--report-gallery-more);

    span {
      opacity: 0.7;
    }
  }
}
</style>
