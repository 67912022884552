<template>
  <div class="wiki-admin" v-if="article && userCanEdit">
    <mcr-button :href="adminEditLink" target="_blank">Edit</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {getWikiArticleAdminUrl} from '@common/utils/utils.admin';

export default {
  props: {
    article: Object,
  },
  computed: {
    userCanEdit() {
      return this.article.is_user_editor;
    },
    adminEditLink() {
      return getWikiArticleAdminUrl(this.article.object_id);
    },
  },
  name: 'WikiAdmin',
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.wiki-admin {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
  margin-bottom: 10px;

  .mcr-button {
    background-color: $admin-color;
  }
}
</style>
