<template>
  <div class="image-caption">
    <img
      :data-src="imgSource"
      class="lazyload with-shadow"
      :width="width"
      :height="height"
      @click="$emit('click-image')"
    />
    <span>{{ caption }}</span>
  </div>
</template>

<script>
export default {
  props: {
    imgSource: {type: String},
    caption: {type: String},
    width: {type: String},
    height: {type: String},
  },
};
</script>

<style scoped lang="scss">
.image-caption {
  margin: calc(#{$paragraph-margin-size} * 2) auto;
  display: table;
  clear: both;

  img {
    object-fit: cover;
  }
  span {
    display: table-caption;
    caption-side: bottom;
    text-align: center;
    font-style: italic;
    color: $supplemental-text-color;
    font-size: 0.9em;
    padding-top: 6px;
  }
}
</style>
