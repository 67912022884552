<template>
  <div class="article-content">
    <div class="title-wrapper">
      <h4>{{ article.title }}</h4>
      <slot name="title-right"></slot>
    </div>
    <paragraph-section>
      <div slot="text">
        <span v-html="article.content" v-if="article.content"></span>
        <table-of-contents v-if="tocOptions.length && isDesktop" :options="tocOptions"></table-of-contents>
      </div>
      <article-meta
        v-if="article.image"
        slot="meta"
        :img-src="article.image"
        :categories="article.categories"
        :attributes="article.attributes"
        :tags="article.tags"
        :place="article.place"
        :clan="article.clan"
        @click-image="showMetaImageGallery"
      ></article-meta>
    </paragraph-section>

    <table-of-contents v-if="tocOptions.length && !isDesktop" :options="tocOptions"></table-of-contents>

    <paragraph-section v-for="part in parts" :key="part.object_id">
      <span slot="title" v-if="part.title" :id="part.titleAnchor">{{ part.title }}</span>
      <div slot="text" v-html="part.content" v-if="part.content"></div>

      <preview-images-gallery
        v-if="part.assets.length > 1"
        :images-src="getPartImagesSrc(part)"
        :setup-gallery-data="getSetupGalleryData(part)"
        :lazy-load="false"
        slot="after-text"
      ></preview-images-gallery>
      <image-caption
        v-else-if="part.assets.length"
        :imgSource="part.assets[0].attachment"
        :caption="part.assets[0].title"
        slot="meta"
        class="single-image"
        @click.native="showPartImageGallery(part)"
      />
    </paragraph-section>

    <images-gallery-overlay-container> </images-gallery-overlay-container>
  </div>
</template>

<script>
import PreviewImagesGallery from '@common/elements/gallery/PreviewImagesGallery';
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import ImageCaption from '@common/elements/image-caption';
import ParagraphSection from '@common/pages/wiki/ParagraphSection';
import ArticleMeta from '@common/pages/wiki/article/meta/ArticleMeta';
import TableOfContents from '@common/pages/wiki/article/meta/TableOfContents';
import {SEARCH_TAB_BY_DEFAULT_FORM} from '@common/utils/consts.search';
import {getGalleryImageData} from '@common/utils/utils';

export default {
  props: {
    article: Object,
  },
  computed: {
    parts() {
      const parts = this.article.parts.map(part => {
        const titleAnchor = this.getTitleAnchorFromPartId(part.object_id);
        return {...part, titleAnchor};
      });
      const searchCategoryParts = this.article.search_categories
        ? this.article.search_categories.map(searchCategory => {
            const objectId = `search-category-${searchCategory.id}`;
            const tab = SEARCH_TAB_BY_DEFAULT_FORM[searchCategory.default_form];
            const query = {category_id: searchCategory.id, tab};
            const href = this.$router.resolve({name: 'search-all-records', query}).href;
            return {
              object_id: objectId,
              title: `${searchCategory.explicit_name} in the My China Roots Database`,
              assets: [],
              content: `<a href="${href}">Search ${searchCategory.default_form_name} records</a>`,
              titleAnchor: this.getTitleAnchorFromPartId(objectId),
            };
          })
        : [];
      return [...parts, ...searchCategoryParts];
    },
    tocOptions() {
      return this.parts
        .map(part => {
          return part.title ? {title: part.title, anchor: `#${part.titleAnchor}`} : null;
        })
        .filter(part => !!part);
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
  },
  methods: {
    getTitleAnchorFromPartId(partId) {
      const short = partId.split('-')[0];
      return `title-${short}`;
    },
    getPartImagesSrc(part) {
      return part.assets ? part.assets.map(asset => asset.attachment) : [];
    },
    getSetupGalleryData(part) {
      return () => {
        return part.assets
          ? part.assets.map((asset, index) =>
              getGalleryImageData(index, asset.attachment, asset.title, {partTitle: part.title}, true)
            )
          : [];
      };
    },
    showPartImageGallery(part) {
      const data = this.getSetupGalleryData(part)();
      this.showImageGallery(data);
    },
    showMetaImageGallery(part) {
      const data = [getGalleryImageData(0, this.article.image, '', {partTitle: this.article.title}, true)];
      this.showImageGallery(data);
    },
    showImageGallery(data) {
      this.$store.commit('setGalleryItemsState', data);
      this.$store.dispatch('displayGalleryAction', 0);
    },
  },
  components: {
    TableOfContents,
    ImageCaption,
    ArticleMeta,
    ParagraphSection,
    PreviewImagesGallery,
    ImagesGalleryOverlayContainer,
  },
  name: 'ArticleContent',
};
</script>

<style scoped lang="scss">
.article-content {
  .title-wrapper {
    display: flex;
    border-bottom: 1px solid $divider-line-color;
    padding-bottom: 20px;
    align-items: flex-end;
    justify-content: space-between;

    h4 {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  h1,
  h4 {
    margin-bottom: $paragraph-margin-size;
    padding-bottom: 20px;
    border-bottom: 1px solid $divider-line-color;
  }

  .table-of-contents {
    margin: 40px 0;
  }

  .paragraph-section {
    margin-top: 40px;
    clear: both;

    .images-preview {
      margin-top: 40px;
    }
  }
  .title-wrapper + .paragraph-section {
    margin-top: 20px;
  }

  .single-image {
    margin-bottom: 0;
    cursor: pointer;
  }
}
</style>
