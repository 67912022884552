import EmailIcon from 'vue-material-design-icons/Email';
import FacebookIcon from 'vue-material-design-icons/Facebook';
import SnapchatIcon from 'vue-material-design-icons/Snapchat';
import TwitterIcon from 'vue-material-design-icons/Twitter';
import WhatsappIcon from 'vue-material-design-icons/Whatsapp';

export const EMAIL = 'email';
export const FACEBOOK = 'facebook';
export const TWITTER = 'twitter';
export const SNAPCHAT = 'snapchat';
export const WHATSAPP = 'whatsapp';

export const networks = {
  [FACEBOOK]: {
    url: 'https://www.facebook.com/sharer/sharer.php?u=@u',
    name: 'Facebook',
    color: '#4267B2',
    icon: FacebookIcon,
  },
  [TWITTER]: {
    url: 'https://twitter.com/intent/tweet?text=@t&url=@u',
    name: 'Twitter',
    color: '#1DA1F2',
    icon: TwitterIcon,
  },
  [WHATSAPP]: {
    url: 'https://api.whatsapp.com/send?text=@t%0D%0A@u',
    name: 'Whatsapp',
    color: '#128C7E',
    icon: WhatsappIcon,
  },
  [SNAPCHAT]: {
    url: 'https://snapchat.com/scan?attachmentUrl=@u',
    name: 'Snapchat',
    color: '#FFFC00',
    textColor: '#000',
    icon: SnapchatIcon,
  },
  [EMAIL]: {url: 'mailto:?subject=@t&body=@u', name: 'Email', color: '#333333', icon: EmailIcon},
};

/**
 * Properties:
 * @u = url
 * @t = title
 * @d = description
 * @q = quote
 * @h = hashtags
 * @m = media
 * @tu = twitterUser
 */
