<template>
  <div class="wiki-home-page">
    <wiki-header :steps="[]"></wiki-header>
    <div class="limited_content">
      <wiki-loading-category v-if="!wikiArticleState || wikiArticleLoadingState"></wiki-loading-category>

      <div v-else>
        <wiki-admin :article="wikiArticleState"></wiki-admin>
        <article-content :article="wikiArticleState"></article-content>
      </div>
    </div>
  </div>
</template>

<script>
import WikiHeader from '@common/pages/wiki/WikiHeader';
import WikiLoadingCategory from '@common/pages/wiki/WikiLoadingCategory';
import WikiAdmin from '@common/pages/wiki/admin/WikiAdmin';
import ArticleContent from '@common/pages/wiki/article/ArticleContent';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Chinese Genealogy Wiki',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Explore our curated knowledge base for Chinese genealogy research tips and resources on the Chinese diaspora.`,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('fetchWikiArticleAction', 'home');
  },
  computed: {
    ...mapGetters(['wikiArticleState', 'wikiArticleLoadingState']),
  },
  components: {WikiAdmin, ArticleContent, WikiHeader, WikiLoadingCategory},
};
</script>

<style scoped></style>
