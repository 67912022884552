<template>
  <div class="icon-input">
    <div class="icon-container"><slot></slot></div>
    <input v-bind="$attrs" v-on="$listeners" ref="input" :class="inputClass" />
  </div>
</template>

<script>
export default {
  props: {
    inputClass: String,
  },
  inheritAttrs: false,
  name: 'IconInput',
};
</script>

<style lang="scss" scoped>
.icon-input {
  position: relative;
  .icon-container {
    position: absolute;
    color: #aaa;
    left: 8px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  input[type='search'] {
    padding-left: 35px;
    width: 100%;
    &::placeholder {
      position: relative;
      top: 1px;
      left: 2px;
      color: #aaa;
    }
  }
}
</style>
