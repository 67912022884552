<template>
  <div class="article-meta">
    <img v-if="imgSrc" :data-src="imgSrc" class="lazyload with-shadow meta-image" @click="$emit('click-image')" />

    <!--    <article-meta-row-->
    <!--      v-for="item of metaItems"-->
    <!--      :title="item.title"-->
    <!--      :items="item.items"-->
    <!--      :key="item.id"-->
    <!--    >-->
    <!--      <component-->
    <!--        slot="item"-->
    <!--        slot-scope="props"-->
    <!--        :is="getComponent(item.type)"-->
    <!--        :item="props.item"-->
    <!--        class="row-item"-->
    <!--      >-->
    <!--        {{ props.item }}-->
    <!--      </component>-->
    <!--    </article-meta-row>-->
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ArticleMetaRow from './ArticleMetaRow';
import RowItemDiv from './items/RowItemDiv';
import RowItemLink from './items/RowItemLink';
import RowItemRouterLink from './items/RowItemRouterLink';

const TYPES = {
  text: 'text',
  link: 'link',
  routerLink: 'routerLink',
};

export default {
  props: {
    imgSrc: String,
    categories: Array,
    attributes: Array,
    tags: Array,
    place: Object,
    clan: Object,
  },
  computed: {
    metaItems() {
      const categories = this.categories
        ? this.getMetaRow(
            'Categories',
            this.categories.map(item => this.getCategoryRouteMetaItem(item)),
            TYPES.routerLink,
            1
          )
        : null;
      const places = this.place ? this.getMetaRow('Place', [this.place.pinyin], TYPES.text, 2) : null;
      const clans = this.clan
        ? this.getMetaRow('Clan', [this.getClanRouteMetaItem(this.clan)], TYPES.routerLink, 3)
        : null;
      const tags = this.tags
        ? this.getMetaRow(
            'Tags',
            this.tags.map(tag => tag.name),
            TYPES.text,
            4
          )
        : null;

      let links = this.attributes ? this.attributes.filter(attr => attr.attribute_value.startsWith('http')) : [];
      let attrs = this.attributes ? this.attributes.filter(attr => !attr.attribute_value.startsWith('http')) : [];
      links = links.map(link => ({href: link.attribute_value, text: link.attribute_name}));
      links = links.length ? this.getMetaRow('Read More', links, TYPES.link, 5) : null;
      attrs = attrs.length
        ? attrs.map((attr, index) => {
            return this.getMetaRow(attr.attribute_name, [attr.attribute_value], TYPES.text, 6 + index);
          })
        : [];

      return [categories, places, clans, links, tags, ...attrs].filter(item => !!item && item.items.length);
    },
    componentsMapping() {
      return {
        [TYPES.text]: RowItemDiv,
        [TYPES.link]: RowItemLink,
        [TYPES.routerLink]: RowItemRouterLink,
      };
    },
  },
  methods: {
    getMetaRow(title, items, type, id) {
      return {title, items, type, id};
    },
    getCategoryRouteMetaItem(category) {
      return {to: {name: 'wiki-category', params: {slug: category.slug}}, text: category.title};
    },
    getClanRouteMetaItem(clan) {
      return {
        to: {name: 'search-tool-surname-detail', query: {word: clan.name_hant || clan.name_ch}},
        text: clan.name_hant || clan.name_ch,
      };
    },
    getComponent(type) {
      return this.componentsMapping[type] || RowItemDiv;
    },
  },
  components: {
    ArticleMetaRow,
    RowItemDiv,
    RowItemLink,
    RowItemRouterLink,
  },
};
</script>

<style lang="scss" scoped>
.article-meta {
  text-align: center;

  .article-meta-row:not(:last-child) {
    margin: 30px 0;
  }

  .row-item {
    margin-bottom: 10px;
  }

  .meta-image {
    cursor: pointer;
  }
}
</style>
