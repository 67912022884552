<template>
  <div class="table-of-contents">
    <div class="bold">Contents</div>
    <ul>
      <li v-for="option in options" :key="option.anchor">
        <a @click="scrollToAnchor(option.anchor)">{{ option.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import consts from '@common/utils/consts';

export default {
  props: {
    options: Array, // [{title: String, anchor: String}]
  },
  computed: {
    scrollOffset() {
      return this.isDesktop ? -consts.MAIN_MENU_HEIGHT : -consts.MAIN_MENU_HEIGHT_MOBILE;
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
  },
  methods: {
    scrollToAnchor(anchor) {
      this.$scrollTo(anchor, {offset: this.scrollOffset, cancelable: false});
    },
  },
  name: 'TableOfContents',
};
</script>

<style lang="scss" scoped>
.table-of-contents {
  background: $background-light;
  padding: 20px;
  width: auto;
  display: table;
  ul {
    padding-inline-start: 20px;
    list-style-type: decimal;
    margin: 0;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    display: block;
  }
}
</style>
