export default {
  getMetaInfo() {
    return {
      title: 'Chinese Genealogy Wiki Search',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Explore our curated knowledge base for Chinese genealogy research tips and resources on the Chinese diaspora.`,
        },
      ],
    };
  },
  search(page, vm) {
    const filters = {q: vm.keyword};
    return new Promise((resolve, reject) => {
      vm.$store
        .dispatch('wikiSearchAction', {...filters, page})
        .then(() => {
          vm.searchRan = true;
          resolve(filters);
        })
        .catch(reject);
    });
  },
  init(vm) {
    vm.searchRan = false;
    vm.$store.commit('setWikiSearchState', []);
    vm.$store.commit('setWikiSearchMetaState', {});
    if (vm.keyword) {
      vm.search(vm.$route.query.page);
    }
  },
  onSwitchPage(page, vm) {
    const query = {...vm.$route.query, page};
    vm.$router.push({query: query});
    vm.scrollToSearchResults();
    vm.search(page);
  },
  scrollToSearchResults(vm) {
    vm.$scrollTo(vm.scrollToElement, {cancelable: false});
  },
  parseFiltersFromQuery(vm) {
    vm.$store.commit('setWikiSearchFormQueryState', vm.keyword);
  },
};
